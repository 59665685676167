<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">기준월</div>
            <ul class="content">
              <li class="item date">
                <input-date
                  v-model="stdMonth"
                  format="YYYY-MM"
                  depth="Year"
                  :notClear="true"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">예약채널</div>
            <ul class="content">
              <li class="item">
                <ejs-multiselect
                  v-model="resveChannels"
                  cssClass="lookup-condition-multiselect"
                  :dataSource="searchOptions.resveChannelOptions"
                  :fields="commonCodeFields"
                  placeHolder="전체"
                  width="240"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :is-shortcut-button="true"
              :ignore="isPopupOpened"
              @click.native="onViewButtonClicked"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <div class="article-left">
          <!-- 아코디언 : accordion / 닫힘 : close -->
          <section class="article-section section-01">
            <div class="section-header">
              <div class="header-left">
                <div class="header-title">가용횟수 초과 예약 목록</div>
                <div class="header-caption">[{{ oneOrMoreReservationsCount }}건]</div>
              </div>
            </div>
            <div class="section-body">
              <ejs-grid-wrapper
                ref="oneOrMoreReservationsGrid"
                :provides="gridProvides"
                :columns="oneOrMoreReservationsGridColumns"
                :dataSource="oneOrMoreReservations"
                :selectionSettings="selectionSettings"
                :allowExcelExport="true"
                @rowSelected="oneOrMoreReservationsGridRowSelected"
              />
            </div>
          </section>
        </div>
        <div class="article-right">
          <!-- 아코디언 : accordion / 닫힘 : close -->
          <section class="article-section section-02">
            <div class="section-header">
              <div class="header-left">
                <div class="header-title">예약 목록</div>
                <div class="header-caption">[{{ reservationsCount }}건]</div>
              </div>
              <div class="header-right">
                <ul class="header-button">
                  <li class="print">
                    <erp-button
                        button-div="FILE"
                        @click.native="excel"
                    >
                      Excel
                    </erp-button>
                  </li>
                  <li>
                    <!-- 예약접수등록 화면 권한 있는 사람만 변경이력 볼 수 있도록 셋팅.-->
                    <erp-button
                        button-div="GET"
                        :is-icon-custom="true"
                        @click.native="viewHistory"
                    >
                      변경이력
                    </erp-button>
                  </li>
                </ul>
              </div>
            </div>
            <div class="section-body">
              <ejs-grid-wrapper
                ref="reservationsGrid"
                :provides="gridProvides"
                :columns="reservationsGridColumns"
                :dataSource="reservations"
                :selectionSettings="selectionSettings"
                :allowExcelExport="true"
                @headerCellInfo="reservationsGridHeaderCellInfo"
                @queryCellInfo="reservationsGridQueryCellInfo"
                @recordClick="reservationsGridRecordClick"
              />
            </div>
          </section>
        </div>
      </article>
    </div>
    <history-popup
      v-if="isHistoryPopupOpen"
      ref="historyPopup"
      @popupClosed="historyPopupClose"
    />
  </div>
</template>

<style scoped>
body .appContent .body-article .article-left{width:627px}
body .appContent .body-article .article-right{width:calc(100% - 627px)}
body .appContent .body-article .section-body{overflow:hidden;border:none}
body .appContent .article-section.section-02 .body-grid >>> .dev-click-area{color:#2e7458!important}
html body.theme-red .appContent .article-section.section-02 .body-grid >>> .dev-click-area{color:#c03737!important}
html body.theme-orange .appContent .article-section.section-02 .body-grid >>> .dev-click-area{color:#d86632!important}
html body.theme-yellow .appContent .article-section.section-02 .body-grid >>> .dev-click-area{color:#e2a429!important}
html body.theme-green .appContent .article-section.section-02 .body-grid >>> .dev-click-area{color:#2e7458!important}
html body.theme-blue .appContent .article-section.section-02 .body-grid >>> .dev-click-area{color:#3170b3!important}
html body.theme-indigo .appContent .article-section.section-02 .body-grid >>> .dev-click-area{color:#5a3dac!important}
html body.theme-purple .appContent .article-section.section-02 .body-grid >>> .dev-click-area{color:#893bab!important}
html body.theme-black .appContent .article-section.section-02 .body-grid >>> .dev-click-area{color:#444!important}
</style>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import commonMixin from '@/views/layout/mixin/commonMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';

import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import InputDate from "@/components/common/datetime/InputDate";
import HistoryPopup from "@/views/common/HistoryPopup";

import moment from "moment";
import {
  DATE_FORMAT_YYYY_MM,
  DATE_FORMAT_YYYY,
  DATE_FORMAT_YYYY_MM_DD
} from "@/utils/date";

import {
  Aggregate,
  VirtualScroll,
  Resize,
  ForeignKey,
  ExcelExport,
  Page,
} from "@syncfusion/ej2-vue-grids";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import { orderBy as _orderBy } from "lodash";
import {openNewWindow} from "@/utils/appInfo";
import {
  commonCodesGetCommonCode,
  commonCodesGetCommonCodeByIdx, commonCodesGetSortNo
} from "@/utils/commonCodes";

export default {
  name: 'OneOrMoreReservations',
  components: {
    EjsGridWrapper,
    InputDate,
    HistoryPopup,
    ErpButton
  },
  mixins: [commonMixin, confirmDialogMixin],
  filters: {},
  data() {
    return {
      isHistoryPopupOpen: false,
      stdMonth: null,
      resveChannels: [],
      searchOptions: {
        resveChannelOptions: null
      },
      commonCodeFields: { text: "comName", value: "comCode" },
      oneOrMoreReservationsCount: 0,
      reservationsCount: 0,
      gridProvides: [Aggregate, VirtualScroll, Resize, ForeignKey, ExcelExport, Page],
      selectionSettings: {
        persistSelection: false,
        type: "Single",
        enableToggle: false
      },
      oneOrMoreReservationsGridColumns: [
        {
          field: "bsnCode",
          headerText: "영업구분",
          groupCode: "BSN_CODE",
          isCommonCodeField: true,
          textAlign: "Center",
          type: "string",
          width: "90",
        },
        {
          field: "resveName",
          headerText: "예약자명",
          type: "string",
          width: "90"
        },
        {
          field: "membershipId",
          headerText: "회원권ID",
          type: "string",
          width: "100"
        },
        {
          field: "resveCount",
          headerText: "예약횟수",
          type: "number",
          format: "N",
          textAlign: "right",
          width: "80"
        },
        {
          field: "dateFrom",
          headerText: "기준시작일",
          type: "string",
          width: "90",
          textAlign: "Center"
        },
        {
          field: "dateTo",
          headerText: "기준종료일",
          type: "string",
          width: "90",
          textAlign: "Center"
        }
      ],
      reservationsGridColumns: [
        {
          field: "resveDate",
          headerText: "예약일자",
          type: "string",
          width: "90",
          textAlign: "center"
        },
        {
          field: "bsnCode",
          headerText: "영업구분",
          groupCode: "BSN_CODE",
          isCommonCodeField: true,
          textAlign: "Center",
          type: "string",
          width: "90",
        },
        {
          field: "resveCourse",
          headerText: "예약코스",
          groupCode: "COURSE_CODE",
          isCommonCodeField: true,
          textAlign: "Center",
          type: "string",
          width: "90",
        },
        {
          field: "resveTime",
          headerText: "예약시간",
          type: "string",
          textAlign: "Center",
          width: "90"
        },
        {
          field: "resveName",
          headerText: "예약자명",
          type: "string",
          width: "90"
        },
        // {
        //   field: "membershipId",
        //   headerText: "회원권ID",
        //   type: "string",
        //   width: "100"
        // },
        {
          field: "memberDiv",
          headerText: "회원구분",
          groupCode: "MEMBER_DIV",
          isCommonCodeField: true,
          type: "string",
          width: "90",
        },
        {
          field: "memberGrade",
          headerText: "회원등급",
          groupCode: "MEMBER_GRADE",
          isCommonCodeField: true,
          type: "string",
          width: "90",
        },
        {
          field: "resveKind",
          headerText: "예약종류",
          groupCode: "RESVE_KIND",
          isCommonCodeField: true,
          type: "string",
          width: "90",
        },
        {
          field: "resveChannel",
          headerText: "예약채널",
          groupCode: "RESVE_CHANNEL",
          isCommonCodeField: true,
          type: "string",
          width: "90",
        },
        {
          field: "insertName",
          headerText: "등록자",
          type: "string",
          width: "90",
        },
        {
          field: "insertDt",
          headerText: "등록일시",
          type: "string",
          width: "130",
        },
        {
          field: "updateName",
          headerText: "수정자",
          type: "string",
          width: "90",
        },
        {
          field: "updateDt",
          headerText: "수정일시",
          type: "string",
          width: "130",
        }
      ],
      oneOrMoreReservations: [],
      reservations: []
    };
  },
  created() {
    this.stdMonth = moment().format(DATE_FORMAT_YYYY_MM);

    this.searchOptions.resveChannelOptions =
      commonCodesGetCommonCode("RESVE_CHANNEL").filter(commonCode =>
        !commonCodesGetCommonCodeByIdx("RESVE_CHANNEL", 1).map(data => data.comCode).includes(commonCode.comCode)
      )
    ;
  },
  destroyed() {},
  computed: {
    isPopupOpened() {
      return this.isHistoryPopupOpen;
    }
  },
  methods: {
    async onViewButtonClicked() {
      const year = moment(this.stdMonth).format(DATE_FORMAT_YYYY);
      const month = moment(this.stdMonth).format("MM");

      this.oneOrMoreReservations = (await GolfErpAPI.fetchOneOrMoreReservations({
        year,
        month,
        resveChannels: this.resveChannels
      })).map(data => ({
        ...data,
        resveChannels: data.resveChannels ? data.resveChannels.replaceAll(" ", "").split(",") : null,
        dateFrom: moment(data.dateFrom).format(DATE_FORMAT_YYYY_MM_DD),
        dateTo: moment(data.dateTo).format(DATE_FORMAT_YYYY_MM_DD)
      }));

      this.oneOrMoreReservationsCount = this.oneOrMoreReservations.length;
    },
    async oneOrMoreReservationsGridRowSelected(args) {
      this.reservations = _orderBy((await GolfErpAPI.fetchOneOrMoreReservationInfos({
        dateFrom: args.data.dateFrom,
        dateTo: args.data.dateTo,
        bsnCode: args.data.bsnCode,
        membershipId: args.data.membershipId,
        resveChannels: args.data.resveChannels
      })).map(data => ({
        ...data,
        resveId: data?.reservationConfirm?.resveId,
        resveName: data?.reservationConfirm?.reservationMember?.resveName,
        membershipId: data?.reservationConfirm?.reservationMember?.membershipId,
        memberDiv: data?.reservationConfirm?.reservationMember?.memberDiv,
        memberGrade: data?.reservationConfirm?.reservationMember?.memberGrade,
        resveKind: data?.reservationConfirm?.reservationMember?.resveKind,
        resveChannel: data?.reservationConfirm?.reservationMember?.resveChannel,
        insertDt: data?.reservationConfirm?.reservationMember?.insertDt,
        updateDt: data?.reservationConfirm?.reservationMember?.updateDt,
        courseCodeSortNo: commonCodesGetSortNo("COURSE_CODE", data?.resveCourse),
      })), ["resveDate", "resveTime", "courseCodeSortNo"]);

      this.reservationsCount = this.reservations.length;
    },
    excel() {
      this.$refs.reservationsGrid.excelExport();
      this.$refs.oneOrMoreReservationsGrid.excelExport();
    },
    async viewHistory() {
      const selectedRecords = this.$refs.reservationsGrid.getSelectedRecords();

      if (selectedRecords.length === 0) {
        this.errorToast("예약 목록을 선택해 주시기 바랍니다");
        return;
      }

      const resveId = selectedRecords[0].resveId;

      if (!resveId) {
        this.errorToast("예약정보가 없습니다");
        return;
      }

      const rawHistories = await GolfErpAPI.fetchReservationHistory(
        resveId
      );

      this.isHistoryPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.historyPopup.show({
          rawHistories,
          fields: {
            Reservation: {
              __name__: "예약확정정보",
              reservationCourse: {
                name: "코스",
                type: "commonCode",
                groupCode: "COURSE_CODE",
                methods: ["create", "update", "delete"],
              },
              reservationTime: {
                name: "예약타임",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              promotionName: {
                name: "프로모션",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              number: {
                name: "예약번호",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              status: {
                name: "예약상태",
                type: "commonCode",
                groupCode: "RESVE_STATUS",
                methods: ["create", "update", "delete"],
              },
              visitorCount: {
                name: "내장인원수",
                type: "number",
                methods: ["create", "update", "delete"],
              },
              discountPaymentCode: {
                name: "할인요금코드",
                type: "commonCode",
                groupCode: "DC_PAYMT_CODE",
                methods: ["create", "update", "delete"],
              },
              teamGreenFee: {
                name: "팀그린피",
                type: "number",
                methods: ["create", "update", "delete"],
              },
              couponNumber: {
                name: "쿠폰번호",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              isSelfCaddie: {
                name: "셀프캐디",
                type: "boolean",
                methods: ["create", "update", "delete"],
              },
              isJoined: {
                name: "조인신청",
                type: "boolean",
                methods: ["create", "update", "delete"],
              },
              applyPenalty: {
                name: "위약적용여부",
                type: "boolean",
                methods: ["create", "update", "delete"],
              },
              penaltyCode: {
                name: "위약코드",
                type: "commonCode",
                groupCode: "PENLTY_CODE",
                methods: ["create", "update", "delete"],
              },
              cancelDivision: {
                name: "취소구분",
                type: "commonCode",
                groupCode: "CANCEL_DIV",
                methods: ["create", "update", "delete"],
              },
              cancelChannel: {
                name: "취소채널",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              cancelDate: {
                name: "취소일자",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              cancelDayCount: {
                name: "취소일수",
                type: "number",
                methods: ["create", "update", "delete"],
              },
              cancelReason: {
                name: "취소사유",
                type: "string",
                methods: ["create", "update", "delete"],
              },
            },
            Booker: {
              __name__: "예약인적정보",
              reservationNumber: {
                name: "예약번호",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              reservationKind: {
                name: "예약종류",
                type: "commonCode",
                groupCode: "RESVE_KIND",
                methods: ["create", "update", "delete"],
              },
              reservationChannel: {
                name: "예약채널",
                type: "commonCode",
                groupCode: "RESVE_CHANNEL",
                methods: ["create", "update", "delete"],
              },
              name: {
                name: "예약자명",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              membershipId: {
                name: "회원권ID",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              memberNumber: {
                name: "회원번호",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              memberDivision: {
                name: "회원구분",
                type: "commonCode",
                groupCode: "MEMBER_DIV",
                methods: ["create", "update", "delete"],
              },
              memberGrade: {
                name: "회원등급",
                type: "commonCode",
                groupCode: "MEMBER_GRADE",
                methods: ["create", "update", "delete"],
              },
              gender: {
                name: "성별",
                type: "commonCode",
                groupCode: "SEX_CODE",
                methods: ["create", "update", "delete"],
              },
              areaCode: {
                name: "지역코드",
                type: "commonCode",
                groupCode: "AREA_CODE",
                methods: ["create", "update", "delete"],
              },
              contactName: {
                name: "연락자명",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              contactTel: {
                name: "연락처",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              groupNumber: {
                name: "단체번호",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              groupKind: {
                name: "단체종류",
                type: "commonCode",
                groupCode: "GRP_KIND",
                methods: ["create", "update", "delete"],
              },
              groupName: {
                name: "단체명",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              reservationRemarks: {
                name: "예약비고",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              recommender: {
                name: "추천인",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              bsnEmployeeName: {
                name: "영업사원명",
                type: "string",
                methods: ["create", "update", "delete"],
              },
            },
            ReservationCompanion: {
              __name__: (type, history) =>
                `예약동반자정보 (${history?.record?.companionName})`,
              number: {
                name: "순번",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              isRepresentative: {
                name: "대표여부",
                type: "boolean",
                methods: ["create", "update", "delete"],
              },
              companionName: {
                name: "동반자명",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              joinGroupNumber: {
                name: "조인그룹번호",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              membershipId: {
                name: "회원권ID",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              memberNumber: {
                name: "회원번호",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              memberDivision: {
                name: "회원구분",
                type: "commonCode",
                groupCode: "MEMBER_DIV",
                methods: ["create", "update", "delete"],
              },
              memberGrade: {
                name: "회원등급",
                type: "commonCode",
                groupCode: "MEMBER_GRADE",
                methods: ["create", "update", "delete"],
              },
              gender: {
                name: "성별",
                type: "commonCode",
                groupCode: "SEX_CODE",
                methods: ["create", "update", "delete"],
              },
              contactTel: {
                name: "연락처",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              lockerNumber: {
                name: "락카번호",
                type: "string",
                methods: ["create", "update", "delete"],
              },
            },
            ReservationDelegator: {
              __name__: "예약위임자정보",
              name: {
                name: "위임자명",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              membershipId: {
                name: "회원권ID",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              memberNumber: {
                name: "회원번호",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              memberDivision: {
                name: "회원구분",
                type: "commonCode",
                groupCode: "MEMBER_DIV",
                methods: ["create", "update", "delete"],
              },
              memberGrade: {
                name: "회원등급",
                type: "commonCode",
                groupCode: "MEMBER_GRADE",
                methods: ["create", "update", "delete"],
              },
              gender: {
                name: "성별",
                type: "commonCode",
                groupCode: "SEX_CODE",
                methods: ["create", "update", "delete"],
              },
              contactTel: {
                name: "연락처",
                type: "string",
                methods: ["create", "update", "delete"],
              },
            },
          },
        });
      });
    },
    historyPopupClose: function () {
      this.isHistoryPopupOpen = false; // 닫히면 DOM에서 없애버림. 새로 띄울 때 초기화시키기
    },
    reservationsGridQueryCellInfo(args) {
      const {
        column: {
          field
        }
      } = args;

      if (
        field === "resveTime" ||
        field === "resveName"
      ) {
        args.cell.classList.add(this.$t("className.grid.clickArea"));
      }
    },
    reservationsGridHeaderCellInfo(args) {
      const {
        cell: {
          column: {
            field
          }
        }
      } = args;

      if (
        field === "resveTime" ||
        field === "resveName"
      ) {
        args.node.classList.add(this.$t("className.grid.clickArea"));
      }
    },
    reservationsGridRecordClick(args) {
      const {
        column: {
          field
        },
        rowData
      } = args;

      if (
        field === "resveTime" ||
        field === "resveName"
      ) {
        let routeData = this.$router.resolve({
          name: 'reservationReceiptRegistration',
          query: {
            bsnDate: rowData.resveDate,
            timeId: rowData.timeId,
            resveCourse: rowData.resveCourse,
          },
        });
        openNewWindow(routeData);
      }
    }
  }
};
</script>
